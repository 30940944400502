<template>
  <div class="row-container">
    <div id="virtual-table-wrapper" ref="virtual-table-wrapper"></div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import VueScreenSize from 'vue-screen-size';

import ColumnSearchCell from '@/components/ColumnSearchCell.vue';
import ColumnHeaderCell from '@/components/ColumnHeaderCell.vue';

export default {
  name: 'VirtualTable',

  components: {
    // eslint-disable-next-line vue/no-unused-components
    ColumnSearchCell,
    // eslint-disable-next-line vue/no-unused-components
    ColumnHeaderCell
  },

  mixins: [VueScreenSize.VueScreenSizeMixin],

  data() {
    return {
      // data
      tableColumn: [], // table column metadata (field, type, width)
      tableColumnHeaderList: [], // header row elements
      tableColumnSearchList: [], // search filter row elements
      searchFilterList: [],
      tableData: [], // 2D array

      // table ui config
      numCol: 0,
      numRow: 100,
      tableMinWidth: 375,
      tableWidth: 375, // = tableMinWidth
      tableHeight: 97, // table min height

      // col config
      minColWidth: 50,
      maxColWidth: 500, // NOTE: max col width is 500px, also check "ColumnHeaderCell"
      tableColumnWidth: [], // {minColWidth, colType: ['text' | 'num' | 'long']}

      // row config
      tableRowHeightMode: 'normal', // 'normal' | 'long'

      // cell config
      cellFontSize: 16, // NOTE: = 1rem
      cellHeight: 30, // normal: 30, long: 100
      colCellHeight: 40, // always 40
      cellPaddingTB: 10, // top, bottom padding (5 + 5)
      cellPaddingLR: 20, // left, right padding (10 + 10)

      // table components
      tableContainer: null,
      tableHorizontalScroller: null,

      // scroll
      fromCol: 0,
      toCol: 0,
      lastRepaintX: null,
      lastScrolled: null,
      maxBufferLeft: 0,
      maxBufferRight: 0,

      // to calculate cell width
      canvas: null,
      context: null
    };
  },

  computed: {
    ...mapState({
      // column data
      embedTableColumnList: (state) => state.embedTableColumnList,
      // table data
      embedTableData: (state) => state.embedTableData
    })
  },

  watch: {
    embedTableData(newData) {
      this.cleanup();
      this.initTable();
    }
  },

  mounted() {
    // init canvas for calculating cell width
    this.canvas = document.createElement('canvas');
    this.context = this.canvas.getContext('2d');
    this.context.font = `${this.cellFontSize}px Arial`;
    this.cleanup();
    this.initTable();
  },

  beforeDestroy() {
    this.cleanup();
  },

  methods: {
    initTable(fromCol = 0) {
      // Step 1: generate table column & data
      // console.log('start generate data...');
      if (this.embedTableData && this.embedTableData.length) this.generateTableData();
      // NO Data... return to default page
      else return;

      // Step 1.2: check if data contains long cell
      if (this.tableColumnWidth.findIndex((el) => el.colType == 'long' || el.colType == 'json') > -1) {
        this.tableRowHeightMode = 'long';
        this.cellHeight = 100 + this.cellPaddingTB;
      }

      // Step 2: create table container
      this.calculateTableSize();
      this.createTableContainer(this.tableWidth, this.tableHeight);

      // Step 3: create table scroller
      this.createTableHorizontalScroller();

      // Step 4: append scroller to table container
      this.tableContainer.appendChild(this.tableHorizontalScroller);

      // Step 5: render table (start from column 0)
      this.renderTable(this.tableContainer, fromCol);

      // Step 6: add on scroll listener
      if (this.tableContainer.attachEvent) this.tableContainer.attachEvent('onscroll', this.onScroll);
      else this.tableContainer.addEventListener('scroll', this.onScroll);

      this.$refs['virtual-table-wrapper'].appendChild(this.tableContainer);
      // console.log('step 7...', this.$refs['virtual-table-wrapper']);

      // As soon as scrolling has stopped, this interval asynchronouslyremoves all
      // the nodes that are not used anymore
      this.rmNodeInterval = setInterval(() => {
        if (Date.now() - this.lastScrolled > 100) {
          const badNodes = document.querySelectorAll('[data-rm="1"]');
          for (let i = 0, l = badNodes.length; i < l; i++) {
            if (this.tableContainer) this.tableContainer.removeChild(badNodes[i]);
          }
        }
        // console.log(document.querySelectorAll('*').length);
      }, 300);

      // Step 8: add on window resize listener
      window.addEventListener('resize', this.onResize);
    },

    generateTableData() {
      // Step 1: get table data
      if (this.searchFilterList.length === 0) this.tableData = this.embedTableData;
      else {
        // apply filter
        this.tableData = this.embedTableData.filter((row) => {
          let shouldHide = true;

          for (let i = 0; i < this.searchFilterList.length; i++) {
            if (!this.searchFilterList[i]) continue;
            const lowRow = row[i].toLowerCase();
            const lowSearch = this.searchFilterList[i].toLowerCase();
            if (lowRow.indexOf(lowSearch) === -1) {
              shouldHide = false;
              break;
            }
          }
          return shouldHide;
        });
      }
      this.numCol = this.embedTableColumnList.length;
      this.numRow = this.tableData.length;

      // Step 2: generate columns
      this.tableColumn = [];
      this.embedTableColumnList.forEach((col, index) => {
        this.tableColumn[this.tableColumn.length] = {
          field: col.name,
          width: Math.max(this.minColWidth, this.getColCellWidth(col.name) + 100), // init min col width
          type: col.type // original column type
        };
      });
      // check if num of columns are equal
      if (this.numCol != this.tableColumn.length) console.log('Something funny just happend...');

      this.tableColumnHeaderList = new Array(this.numCol);
      this.tableColumnSearchList = new Array(this.numCol);

      // Step 3: calculate min cell width (get the max cell width for each column)
      this.tableColumnWidth = this.tableColumn.map((col) => {
        return {
          minColWidth: col.width,
          colType: this.toCellType(col.type) // DEFAULT is text
        };
      });

      for (let i = 0; i < this.numRow; i++) {
        for (let j = 0; j < this.numCol; j++) {
          this.tableColumnWidth[j] = this.getCellMeta(this.tableColumnWidth[j], this.tableData[i][j]);
        }
      }

      // set max col width for each col
      this.tableColumnWidth.forEach((col, index) => {
        col.minColWidth = Math.min(this.maxColWidth, col.minColWidth);
      });
    },

    createTableContainer(tableWidth, tableHeight) {
      // console.log("create container: ", tableWidth, tableHeight);
      if (this.tableContainer) {
        // console.log('table container already created...');
      } else {
        this.tableContainer = document.createElement('div');
        this.tableContainer.style.width = `${tableWidth}px`;
        this.tableContainer.style.height = `${tableHeight - 112}px`;
        this.tableContainer.classList.add('virtual-table-container');
      }
    },

    createTableHorizontalScroller() {
      const scrollWidth = this.tableColumnWidth.reduce((acc, cur) => acc + cur.minColWidth, 0);
      // console.log("scrollWidth is; ", scrollWidth, this.tableColumnWidth);
      if (this.tableHorizontalScroller) {
        // console.log('table horizontal scroller already created...');
      } else {
        this.tableHorizontalScroller = document.createElement('div');
        this.tableHorizontalScroller.style.opacity = 0;
        this.tableHorizontalScroller.style.position = 'absolute';
        this.tableHorizontalScroller.style.top = 0;
        this.tableHorizontalScroller.style.left = 0;
        this.tableHorizontalScroller.style.width = `${scrollWidth}px`;
        this.tableHorizontalScroller.style.height = '1px';
      }
    },

    renderTable(container, fromCol) {
      // const start2 = new Date().getTime();
      const toCol = this.calculateToCol(fromCol, this.tableWidth);
      // console.log('toCol is: ', fromCol, toCol, toCol - fromCol);

      // Append all the new columns in a document fragment that we will later append to the parent node
      const fragment = document.createDocumentFragment();

      // 1. append column search filter
      fragment.appendChild(this.createSearchRow(fromCol, toCol));

      // 2. append column header
      fragment.appendChild(this.createColRow(fromCol, toCol));

      // 3. append rows
      for (let i = 0; i < this.numRow; i++) {
        fragment.appendChild(this.createRow(i, fromCol, toCol));
      }

      // Hide and mark obsolete nodes for deletion.
      for (let j = 1, l = container.childNodes.length; j < l; j++) {
        container.childNodes[j].style.display = 'none';
        container.childNodes[j].setAttribute('data-rm', '1');
      }
      container.appendChild(fragment);

      // const elapsed2 = new Date().getTime() - start2;
      // console.log('time elapsed2: ', elapsed2);
    },

    /* ======================================================
     * Clean up: reset everything here
     * ====================================================== */

    cleanup() {
      // clear intervals and listeners
      clearInterval(this.rmNodeInterval);
      window.removeEventListener('resize', this.onResize);

      // table components
      try {
        this.$refs['virtual-table-wrapper'].removeChild(this.tableContainer);
      } catch (e) {
        // console.log(e);
      }
      this.tableContainer = null;
      this.tableHorizontalScroller = null;

      // data
      this.tableColumn = [];
      this.tableColumnHeaderList = [];
      this.tableColumnSearchList = [];
      this.tableData = [];

      // table ui config
      this.numCol = 0;
      this.numRow = 100;
      this.tableMinWidth = 375;
      this.tableWidth = 375; // tableMinWidth
      this.tableHeight = 97; // tableMinHeight: 97

      // col config
      this.minColWidth = 50;
      this.maxColWidth = 500; // NOTE: max col width is 500px
      this.tableColumnWidth = []; // {minColWidth, colType: ['text' | 'num' | 'long']}

      // row config
      this.tableRowHeightMode = 'normal'; // 'normal' | 'long'

      // cell config
      this.cellFontSize = 16; // NOTE: 1px plus original size
      this.cellHeight = 30; // normal: 30, long: 100
      this.colCellHeight = 40; // always 40
      this.cellPaddingTB = 10; // top, bottom padding (5 + 5)
      this.cellPaddingLR = 20; // left, right padding (10 + 10)

      // scroll
      this.fromCol = 0;
      this.toCol = 0;
      this.lastRepaintX = null;
      this.lastScrolled = null;
      this.maxBufferLeft = 0;
      this.maxBufferRight = 0;
    },

    /* ======================================================
     * Draw Different Type of Rows
     * ====================================================== */

    createSearchRow(fromCol, toCol) {
      const rowElement = document.createElement('div');
      rowElement.style.display = 'table-row';
      rowElement.style.height = 'auto';
      rowElement.classList.add('row-wrapper');
      let left = this.tableColumnWidth.slice(0, fromCol).reduce((acc, cur) => acc + cur.minColWidth, 0);

      this.maxBufferLeft = left;
      for (let j = fromCol; j < toCol; j++) {
        // create search elemnt if not already created
        const cellElement = document.createElement('div');
        if (this.tableColumnSearchList[j]) {
          cellElement.appendChild(this.tableColumnSearchList[j].$el);
        } else {
          // create new header cell
          setTimeout(() => {
            const ComponentClass = Vue.extend(ColumnSearchCell);
            const searchColInstance = new ComponentClass({
              propsData: {
                colIndex: j,
                searchValue: this.searchFilterList[j]
              }
            });
            searchColInstance.$mount();
            searchColInstance.$on('searchFilter', (i, val) => {
              this.searchFilterList[i] = val;
              this.applyFilter();
            });
            this.tableColumnSearchList[j] = searchColInstance;
            cellElement.appendChild(searchColInstance.$el);
          }, 1);
        }

        // calculate cell position
        cellElement.style.left = `${left + 1}px`; // NOTE: +1 for border
        cellElement.style.right = `calc(100% - ${left}px - ${this.tableColumnWidth[j].minColWidth}px - ${this.cellPaddingLR}px)`;
        left += this.tableColumnWidth[j].minColWidth + this.cellPaddingLR;

        cellElement.classList.add('cell-element');
        cellElement.classList.add('search-filter');
        cellElement.style.backgroundColor = '#f2f6fc';
        rowElement.appendChild(cellElement);

        // set max buffer right
        if (j == toCol - 1) {
          this.maxBufferRight = left;
        }
      }

      rowElement.style.position = 'absolute';
      rowElement.style.top = '1px'; // NOTE 1px for top border
      return rowElement;
    },

    createColRow(fromCol, toCol) {
      const rowElement = document.createElement('div');
      rowElement.style.display = 'table-row';
      rowElement.style.height = 'auto';
      rowElement.classList.add('row-wrapper');
      let left = this.tableColumnWidth.slice(0, fromCol).reduce((acc, cur) => acc + cur.minColWidth, 0);

      this.maxBufferLeft = left;
      for (let j = fromCol; j < toCol; j++) {
        // show schema text right besides col title
        const cellElement = document.createElement('div');
        if (this.tableColumnHeaderList[j]) {
          cellElement.appendChild(this.tableColumnHeaderList[j].$el);
        } else {
          // create new header cell
          const ComponentClass = Vue.extend(ColumnHeaderCell);
          const headerInstance = new ComponentClass({
            propsData: {
              colIndex: j,
              colType: this.tableColumn[j].type,
              colName: this.tableColumn[j].field,
              value: this.tableColumn[j].field
            }
          });
          headerInstance.$mount();
          this.tableColumnHeaderList[j] = headerInstance;

          cellElement.appendChild(headerInstance.$el);
        }

        // calculate cell position
        cellElement.style.left = `${left + 1}px`; // NOTE: +1 for border
        cellElement.style.right = `calc(100% - ${left}px - ${this.tableColumnWidth[j].minColWidth}px - ${this.cellPaddingLR}px)`;
        left += this.tableColumnWidth[j].minColWidth + this.cellPaddingLR;

        cellElement.classList.add('cell-element');
        cellElement.style.backgroundColor = '#f2f6fc';
        rowElement.appendChild(cellElement);

        // set max buffer right
        if (j == toCol - 1) {
          this.maxBufferRight = left;
        }
      }

      rowElement.style.position = 'absolute';
      rowElement.style.top = `${this.colCellHeight + 3}px`;
      return rowElement;
    },

    createRow(i, fromCol, toCol) {
      const rowElement = document.createElement('div');
      rowElement.style.display = 'table-row';
      rowElement.style.height = 'auto';
      rowElement.classList.add('row-wrapper');
      let left = this.tableColumnWidth.slice(0, fromCol).reduce((acc, cur) => acc + cur.minColWidth, 0);

      this.maxBufferLeft = left;
      for (let j = fromCol; j < toCol; j++) {
        const cellElement = document.createElement('div');
        let cellText;

        if (this.tableColumn[j].type == 'RECORD') {
          const pre = document.createElement('pre');
          const code = document.createElement('code');
          code.textContent = this.tableData[i][j]; // NOTE: textContent is important
          pre.appendChild(code);
          cellElement.appendChild(pre);
        } else {
          cellText = document.createTextNode(this.tableData[i][j]);
          cellElement.appendChild(cellText);
        }

        // calculate cell position
        cellElement.style.left = `${left + 1}px`; // NOTE: +1 for border
        cellElement.style.right = `calc(100% - ${left}px - ${this.tableColumnWidth[j].minColWidth}px - ${this.cellPaddingLR}px)`;
        left += this.tableColumnWidth[j].minColWidth + this.cellPaddingLR;

        cellElement.classList.add('cell-element');
        cellElement.classList.add(this.tableColumnWidth[j].colType);
        if (this.tableRowHeightMode == 'long') cellElement.classList.add('high');
        if (this.tableData[i][j] == null || this.tableData[i][j] === 'null') cellElement.classList.add('null');
        // check if chinese
        else if (
          /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u.test(
            this.tableData[i][j]
          )
        )
          cellElement.classList.add('chinese');
        else if (
          /[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]/.test(
            this.tableData[i][j]
          )
        )
          cellElement.classList.add('japanese');

        rowElement.appendChild(cellElement);

        // set max buffer right
        if (j == toCol - 1) {
          this.maxBufferRight = left;
        }
      }
      // console.log("buffer is: ", this.maxBufferLeft, this.maxBufferRight);

      rowElement.style.position = 'absolute';
      rowElement.style.top = `${this.colCellHeight * 2 + i * this.cellHeight + i + 3}px`;
      return rowElement;
    },

    /* ======================================================
     * Binding event functions
     * ====================================================== */

    onScroll(e) {
      const { scrollLeft } = e.target; // Triggers reflow

      let shouldRepaint = false;
      if (scrollLeft > this.lastRepaintX)
        // scroll left
        shouldRepaint = scrollLeft > this.maxBufferRight - this.tableWidth;
      else shouldRepaint = scrollLeft < this.maxBufferLeft; // scroll right

      if (shouldRepaint) {
        const first = this.calculateFromCol(scrollLeft);
        this.tableScrollLeft = scrollLeft;
        this.fromCol = first;
        this.renderTable(this.tableContainer, Math.max(0, first));
        this.lastRepaintX = scrollLeft;
      }

      this.lastScrolled = Date.now();
      // eslint-disable-next-line no-unused-expressions
      e.preventDefault && e.preventDefault();
    },

    onResize() {
      // console.log('onResize()...');
      this.calculateTableSize();
      this.tableContainer.style.width = `${this.tableWidth}px`;
      this.tableContainer.style.height = `${this.tableHeight - 112}px`;
    },

    /* ======================================================
     * Calculate Size and Params functions
     * ====================================================== */

    calculateTableSize() {
      // 1. calculate width (100% - 250px), min 800px
      this.tableWidth = Math.max(this.$vssWidth, this.tableMinWidth);
      // 2. calculate height (100% - px) * 80%, min 600px
      if (this.tableData && this.tableData.length > 0) this.tableHeight = this.$vssHeight; // 360 is a magical number
    },

    calculateToCol(start, limit) {
      let count = Math.min(this.numCol, start + 15); // render at least 15 columns (buffer) by default
      let sum = 0;
      for (let i = start; i < this.numCol; i++) {
        count++;
        sum += this.tableColumnWidth[i].minColWidth; // IMPORTANT
        if (sum > limit) return Math.min(this.numCol, count);
      }
      return Math.min(this.numCol, count);
    },

    calculateFromCol(scrollLeft) {
      let count = -1;
      let sum = 0;
      for (let i = 0; i < this.numCol; i++) {
        count++;
        sum += this.tableColumnWidth[i].minColWidth; // IMPORTANT
        if (sum > scrollLeft) return count;
      }
      this.tableScrollLeft = sum;
      this.fromCol = count;
      return count;
    },

    /* ======================================================
     * Apply Filter
     * ====================================================== */

    applyFilter() {
      this.cleanup();
      this.initTable(this.fromCol);
    },

    /* ======================================================
     * Helper functions
     * ====================================================== */

    getColCellWidth(text) {
      return Math.ceil(this.context.measureText(text).width);
    },

    getCellMeta(col, text) {
      // check if null or undefined
      // NOTE: 'null == undefined' is true
      // NOTE: just return if empty
      if (text == null || text === '') {
        return col;
      }

      // NOTE: assuming all cell data are type of string
      if (text.length > 100) {
        col.colType = 'long';
      }

      col.minColWidth = Math.max(
        col.minColWidth,
        String(text)
          .substring(0, 1024) // only get first 1024 characters for performace
          .split('\n') // split by new line in text
          .map((t) => Math.ceil(this.context.measureText(t).width))
          .reduce((acc, cur) => (acc > cur ? acc : cur))
      );

      return col;
    },

    toCellType(colType) {
      switch (colType) {
        case 'RECORD':
          return 'json';
        case 'INTEGER':
        case 'FLOAT':
        case 'NUMERIC':
        case 'TIMESTAMP':
          return 'num';
        case 'STRING':
        case 'BOOLEAN':
        case 'DATE':
        case 'DATETIME':
        case 'TIME':
        case 'BYTES':
        default:
          return 'text';
      }
    }
  } // END methods
};
</script>

<style>
.virtual-table-container {
  position: relative;
  overflow: auto;
  will-change: transform;
  padding: 0;
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #f5f5f5;
}

.row-wrapper {
  width: 100%;
  border: 0;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  position: relative;
  height: auto;
}

.row-wrapper > .cell-element:first-child {
  border-left: 1px solid #e4e7ed;
}

.cell-element {
  white-space: pre;
  position: absolute;

  min-height: 16px; /* important */
  color: #4a4a4a;
  background-color: white;
  font-family: Arial;
  font-size: 14px;
  text-overflow: ellipsis;
  vertical-align: middle;
  border-right: 1px solid #e4e7ed;
  border-bottom: 1px solid #e4e7ed;

  cursor: default;
  padding-top: 6px; /* important */
  padding-bottom: 7px; /* important */
  padding-left: 10px;
  padding-right: 10px;
  margin: -1px 0 0 -1px;

  text-align: left;
}

.cell-element.search-filter {
  border-top: 1px solid #e4e7ed;
  padding-top: 7px;
  padding-bottom: 9px;
}

/* number cell */
.cell-element.num {
  color: #4a4a4a;
  text-align: right;
}

/* long text cell */
.cell-element.long {
  white-space: pre-wrap;

  max-height: 97px; /* important */
  overflow-y: auto;
  overflow-wrap: break-word;
  will-change: auto;
}

.cell-element.json {
  white-space: pre;
  max-width: 60em;
  max-height: 97px; /* important */
  overflow-y: auto;
  overflow-wrap: break-word;
  will-change: transform;
}

.cell-element.high {
  min-height: 97px; /* important */
}

/* null cell */
.cell-element.null {
  font-style: italic;
  color: #909399;
}

/* chinese */
.cell-element.chinese,
.cell-element.japanese {
  padding-top: 4px;
  padding-bottom: 5px;
}
</style>
