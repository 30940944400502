<template>
  <div :key="colIndex" class="table-search-cell">
    <input
      :ref="'debounceFilterSearchRef_' + colIndex"
      v-model="value"
      class="search-input"
      placeholder="Search"
      type="text"
      @input="debounceSearchFilterQuery()"
    />
  </div>
</template>

<script>
export default {
  name: 'ColumnSearchCell',
  props: {
    colIndex: {
      type: Number,
      default: -1
    },
    searchValue: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      value: ''
    };
  },

  mounted() {
    this.value = this.searchValue;
  },

  methods: {
    debounceSearchFilterQuery() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        // search filter
        this.$emit('searchFilter', this.colIndex, this.value);
      }, 500);
    }
  }
};
</script>

<style scoped>
.search-input {
  width: calc(100% - 1rem);
  height: 26px;
  font-size: 14px;
  margin: -3px 0;
  border: 1px solid #e4e7ed;
  border-radius: 0.25rem;
  padding-left: 0.5rem;
}

.search-input:hover {
  border-color: #c0c4cc;
}

.search-input:focus {
  border-color: #409eff;
  outline: none;
}
</style>
