import request from '@/utils/request';

export function getWidgetsByPubAppId(appId) {
  return request.get('/ba/pub-app/widgets', { params: { appId } });
}

/**
 * Fetches the data for a widget in a publicly accessible business app
 * @param {Object} params
 * @param {Number} params.appId - id of the public business app
 * @param {Number} params.widgetId - id of the widget in the public business app
 * @param {Number} params.guestId - (optional) guest id generated for access
 * @returns {Promise<Object>}
 */
export function getWidgetData(params) {
  return request.get('/ba/pub-app/widget-data', { params });
}

/**
 * Handles applying an action to a publicly available business app
 * @param {Object} params
 * @param {String} params.appId - the publicly available app id
 * @param {String} params.appType - the type of the app
 * @returns {Promise<Object>} the result of the action
 */
export function applyPublicAction(params) {
  return request.post('/ba/pub-app/public-action', params);
}

export default {
  getWidgetsByPubAppId
};
