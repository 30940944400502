import Vue from 'vue';
import VueRouter from 'vue-router';

// Layout
import MainFrame from '@/views/MainFrame.vue';

// Embed Related Pages
import EmbedDefaultPage from '@/views/EmbedDefaultPage.vue';
import EmbedHome from '@/views/EmbedHome.vue';

// Business App Related Pages
import ChartingApp from '@/views/ChartingApp.vue';
import SpreadsheetApp from '@/views/SpreadsheetApp.vue';

// Error (404) Page
import ErrorPage from '@/views/ErrorPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/embed',
    name: 'MainFrame',
    component: MainFrame,
    redirect: { name: 'EmbedDefaultPage' },
    children: [
      {
        path: '',
        name: 'EmbedDefaultPage',
        component: EmbedDefaultPage
      },
      {
        path: ':embed_id',
        name: 'EmbedHome',
        component: EmbedHome
      }
    ]
  },
  {
    path: '/charting-app/:assetId',
    name: 'ChartingApp',
    component: ChartingApp
  },
  {
    path: '/spreadsheet-app/:assetId',
    name: 'SpreadsheetApp',
    component: SpreadsheetApp
  },
  {
    path: '*',
    redirect: { name: 'error-page' }
  },
  {
    path: '/404',
    name: 'error-page',
    component: ErrorPage
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
