import request from '@/utils/request';

/**
 * Checks whether or not an asset is publicly accessible
 * @param {Number} assetId - the id of the asset
 * @returns {Promse<Obj>}
 */
export function getPublicAccess(assetId) {
  return request.get('/iam/asset/public-access', { params: { assetId } });
}

/**
 * Fetches a public asset
 * @param {Object} params - params object for the request
 * @param {Number} params.assetId - the id of the asset you wish to get details for
 * @param {Boolean} params.withRules - (optional) specify whether or not you wish to have the public rules included in the response
 * @returns {Promise<Object>} - A promise when resolved contains object with details about the asset
 */
export function getPublicAsset(params) {
  return request.get('/iam/asset/public-asset', { params });
}

export default {
  getPublicAccess,
  getPublicAsset
};
