<template>
  <button :class="['a-btn', type]" :disabled="disabled" @click="$emit('click')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'AchoButton',
  props: {
    type: {
      default: 'default',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style scoped>
.a-btn {
  height: fit-content;
  padding: 0.5rem 1rem !important;
  border-radius: 0.25rem;
  font-weight: 500;
  cursor: pointer;
}

.a-btn:focus,
.a-btn:hover {
  outline: none;
}

/**
  *================================================================
  * Default
  *================================================================
  */

.a-btn.default {
  background-color: #fff;
  border: 1px solid #e0e0e0;
}

.a-btn.default:hover {
  color: var(--blue);
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}

.a-btn.default:active {
  color: #3a8ee6;
  border-color: #3a8ee6;
  outline: none;
}

/**
  *================================================================
  * Primary
  *================================================================
  */

.a-btn.primary {
  background-color: var(--blue);
  border: 1px solid var(--blue);
  color: #fff;
}

.a-btn.primary:hover {
  background: #66b1ff;
  border-color: #66b1ff;
  color: #fff;
}

.a-btn.primary:active {
  background: #3a8ee6;
  border-color: #3a8ee6;
  color: #fff;
}

/**
  *================================================================
  * Disabled (should be last in css)
  *================================================================
  */

.a-btn:disabled {
  cursor: not-allowed;
}

.a-btn.default:disabled {
  color: #828282;
  background-color: #fff;
  border: 1px solid #e0e0e0;
}

.a-btn.primary:disabled {
  background: #66b1ff;
  border-color: #66b1ff;
  color: #fff;
}
</style>
