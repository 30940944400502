<template>
  <div v-loading="loading" class="charting-app-container">
    <InfoMask :visible="maskVisible" :message="infoMsg" />
    <AchoChartingApp
      :widget-list="widgetList"
      :enable-global-filters="enableGlobalFilters"
      :allow-move="enableMove"
      :allow-resize="enableResize"
      :toolbar-config="toolbarConfig"
    />
  </div>
</template>

<script>
import { getPublicAsset } from '@/api/asset';
import { getWidgetsByPubAppId, getWidgetData } from '@/api/businessApps';
import AchoChartingApp from '@acho-inc/charting-app';
import InfoMask from '@/components/InfoMask';

export default {
  name: 'ChartingApp',

  components: {
    AchoChartingApp,
    InfoMask
  },

  data() {
    return {
      loading: false,
      infoMsg: '',
      maskVisible: false,
      appId: undefined,
      assetId: undefined,
      widgetList: [],
      toolbarConfig: {
        visible: true,
        showBranding: true
      },
      enableMove: false,
      enableResize: false,
      enableGlobalFilters: false
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.loading = true;
      const { assetId } = this.$route.params;
      await this.loadChartingApp(assetId);
      this.loading = false;
    },

    async loadChartingApp(assetId) {
      try {
        // Fetch the asset's record, and verify that it has public access
        const pubAssetResp = await getPublicAsset({ assetId, withRules: true });
        // console.log('pub asset resp: ', pubAssetResp);
        if (pubAssetResp.data.error) {
          // console.log(pubAssetResp.data.error);
          this.handleNoContent('This content is not publicly available');
          return;
        }
        const pubAsset = pubAssetResp.data;
        const { id: appId, rules } = pubAsset;
        this.setAppRules(JSON.parse(rules));

        // Fetch the widget list for the charting app
        const widgetListResp = await getWidgetsByPubAppId(appId);
        const { data: widgetList } = widgetListResp;
        this.widgetList = widgetList.map((w) => ({ ...w, config: JSON.parse(w.config), static: !!w.static, loading: true }));

        // Load the data for each widget in the app
        this.loadWidgetData();

        // Holding onto the asset id and app id as they may come in handy later
        this.assetId = assetId;
        this.appId = appId;
      } catch (error) {
        // console.log('Error getting public asset: ', error);
        this.handleNoContent();
      }
    },

    async loadWidgetData() {
      try {
        const _widgetList = [];
        // Construct a list of requests that will fetch the widget's data
        // NOTE: allows requests to get widget's data to fail. We may want to handle this
        // in a more friendly fashion. Even if the widget fails to get data, we keep it in the widget list
        // as this feels less abrasive then just removing it from the layout.
        // NOTE: an error will be raised on widgets that don't have 'data' such as global filters, text components, etc.
        // The backend should not raise a bad request in these cases.
        const widgetDataPromises = this.widgetList.map((w) =>
          getWidgetData({ appId: w.app_id, widgetId: w.id }).catch((e) => {
            _widgetList.push({ ...w, loading: false });
          })
        );

        // Resolve the list of requests
        const dataResponses = await Promise.all(widgetDataPromises);

        // For each response, map the widget's data to the corresponding widget
        dataResponses.forEach((d) => {
          if (d?.data?.widgetId) {
            const { widgetId, data } = d.data;
            const widget = this.widgetList.find((w) => w.id === widgetId);

            // Merge the widget's data and set loading to false
            _widgetList.push({ ...widget, chartData: data, loading: false });
          }
        });
        // Update the widget list
        this.widgetList = [..._widgetList];
      } catch (error) {
        // console.log('error loading widget data', error);
        this.handleNoContent();
      }
    },

    setAppRules(rules) {
      const { enableMove, enableResize, enableGlobalFilters, showBranding } = rules;
      this.enableMove = enableMove;
      this.enableResize = enableResize;
      // this.enableGlobalFilters = enableGlobalFilters; // enable when global filters actually work
      this.toolbarConfig.showBranding = showBranding !== undefined ? showBranding : true;
    },

    handleNoContent(msg) {
      const _msg = msg || 'The content you are looking for does not exist';
      this.infoMsg = _msg;
      this.maskVisible = true;
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.charting-app-container {
  height: calc(100vh - 1rem);
}
</style>
