<template>
  <div class="embed-default-page">
    <div class="info-icon-wrapper" style="margin-left: 1rem; margin-top: 0;">
      <img class="warn-icon" :src="warnIcon" alt="warn" style="width: 1.2rem; vertical-align: top;" />
    </div>
    <p style="text-align: left; font-size: 14px; margin: 0 0.5rem; line-height: 1.4rem;">
      No such table!
    </p>
  </div>
</template>

<script>
export default {
  name: 'EmbedDefaultPage',
  data() {
    return {
      warnIcon: require('@/assets/warning.svg')
    };
  }
};
</script>

<style scoped>
.embed-default-page {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 30%;
  width: 100%;
  margin: 0 auto;
}

.info-icon-wrapper {
  margin: auto 0;
}
</style>
