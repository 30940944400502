import Vue from 'vue';
import { Dialog, Checkbox, Row, Col, Input, Message, Loading } from 'element-ui';

// 3rd party modules
import axios from 'axios';
import VTooltip from 'v-tooltip';

import { isProduction, constants } from './utils/globalConstants';

import router from './router';
import store from './store';

// App
import App from './App.vue';
import './global.css';

Vue.config.productionTip = isProduction;

// axios
axios.defaults.baseURL = constants.serverAddr;

// element ui
Vue.use(Dialog);
Vue.use(Checkbox);
Vue.use(Row);
Vue.use(Col);
Vue.use(Input);

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;

// tooltip
Vue.use(VTooltip);

new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
