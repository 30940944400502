<template>
  <el-dialog
    id="data-usage-agreement-dialog"
    title="Data Usage Agreement"
    :before-close="closeDataUsageAgreement"
    :visible.sync="dataUsageAgreementVisible"
  >
    <div style="word-break: break-word; height: 500px; overflow: auto;">
      <p>Acho Software Inc.</p>
      <p>Data Usage Agreement</p>

      <p>
        This is an agreement (“Agreement”) between you the downloader (“Downloader”) and the owner of the materials (“Data
        Provider”) governing the use of the materials (“Materials”) to be downloaded.
      </p>

      <p class="title">I. Acceptance of this Agreement</p>
      <p>
        By downloading or otherwise accessing the Materials, Downloader represents his/her acceptance of the terms of this
        Agreement.
      </p>
      <p class="title">II. Modification of this Agreement</p>
      <p>
        Data Providers may modify the terms of this Agreement at any time. However, any modifications to this Agreement will
        only be effective for downloads subsequent to such modification. No modifications will supersede any previous terms
        that were in effect at the time of the Downloader’s download.
      </p>
      <p class="title">II. Modification of this Agreement</p>
      <p>
        Use of the Materials include but are not limited to viewing parts or the whole of the content included in the
        Materials; comparing data or content from the Materials with data or content in other Materials; verifying research
        results with the content included in the Materials; and extracting and/or appropriating any part of the content
        included in the Materials for use in other projects, publications, research, or other related work products.
      </p>

      <p class="title">III. Use of the Materialst</p>
      <p>
        Use of the Materials include but are not limited to viewing parts or the whole of the content included in the
        Materials; comparing data or content from the Materials with data or content in other Materials; verifying research
        results with the content included in the Materials; and extracting and/or appropriating any part of the content
        included in the Materials for use in other projects, publications, research, or other related work products.
      </p>

      <p style="margin-left: 1rem;">A. Representations: In Use of thee Materials, Downloader represents that</p>

      <p style="margin-left: 2rem;">
        a. Downloader is not bound by any pre-existing legal obligations or other applicable laws that prevent Downloader
        from downloading or using the Materials.
      </p>
      <p style="margin-left: 2rem;">b. Downloader will not use the Materials in any way prohibited by applicable laws.</p>
      <p style="margin-left: 2rem;">
        c. Downloader has no knowledge of and will therefore not be responsible for any restrictions regarding the use of
        Materials beyond what is described in this Agreement.
      </p>
      <p style="margin-left: 2rem;">
        d. Downloader has no knowledge of and will therefore not be responsible for any inaccuracies and any other such
        problems with regards to the content of the Materials and the accompanying citation information.
      </p>

      <p style="margin-left: 1rem;">B. Restrictions: In his/her Use of the Materials, Downloaders cannot</p>
      <p style="margin-left: 2rem;">
        a. Obtain information from the Materials that results in Downloader or any third party(ies) directly or indirectly
        identifying any research subjects with the aid of other information acquired elsewhere.
      </p>
      <p style="margin-left: 2rem;">
        b. Produce connections or links among the information included in Data Provider’s datasets (including information in
        the Materials), or between the information included in Data Provider’s datasets (including information in the
        Materials) and other third-party information that could be used to identify any individuals or organizations, not
        limited to research subjects; and
      </p>
      <p style="margin-left: 2rem;">
        c. extract information from the Materials that could aid Downloader in gaining knowledge about or obtaining any means
        of contacting any subjects already known to Downloader.
      </p>

      <p class="title">IV. Representations and Warranties</p>
      <p>
        Data Provider REPRESENTS THAT Data Provider HAS ALL RIGHTS REQUIRED TO MAKE AVAILABLE AND DISTRIBUTE THE MATERIALS.
        EXCEPT FOR SUCH REPRESENTATION, THE MATERIALS IS PROVIDED “AS IS” AND “AS AVAILABLE” AND WITHOUT WARRANTY OF ANY
        KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A
        PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE
        EXPRESSLY DISCLAIMED.
      </p>

      <p>
        WITHOUT LIMITING THE FOREGOING, Data Provider DOES NOT WARRANT THAT: (A) THE MATERIALS ARE ACCURATE, COMPLETE,
        RELIABLE, OR CORRECT; (B) THE MATERIALS FILES WILL BE SECURE ; (C) THE MATERIALS WILL BE AVAILABLE AT ANY PARTICULAR
        TIME OR LOCATION; (D) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (E) THE MATERIALS AND ACCOMPANYING FILES ARE FREE OF
        VIRUSES OR OTHER HARMFUL COMPONENTS; OR (F) THE RESULTS OF USING THE MATERIALS WILL MEET DOWNLOADER’S REQUIREMENTS.
        DOWNLOADER’S USE OF THE MATERIALS IS SOLELY AT DOWNLOADER’S OWN RISK.
      </p>

      <p class="title">V. Limitation of Liability</p>
      <p>
        IN NO EVENT SHALL Data Provider BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL
        THEORY WITH RESPECT TO THE MATERIALS (I) FOR ANY DIRECT DAMAGES, OR (II) FOR ANY LOST PROFITS OR SPECIAL, INDIRECT,
        INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER.
      </p>
      <p class="title">VI. Indemnification</p>
      <p>
        Downloader will indemnify and hold Data Provider harmless from and against any and all loss, cost, expense,
        liability, or damage, including, without limitation, all reasonable attorneys’ fees and court costs, arising from the
        i) Downloader’s misuse of the Materials; (ii) Downloader’s violation of the terms of this Agreement; or (iii)
        infringement by Downloader or any third party of any intellectual property or other rights of any person or entity
        contained in the Materials. Such losses, costs, expenses, damages, or liabilities shall include, without limitation,
        all actual, general, special, and consequential damages.
      </p>
      <p class="title">VII. Dispute Resolution</p>
      <p>
        Downloader and Data Provider agree that any cause of action arising out of or related to the download or use of the
        Materials must commence within one (1) year after the cause of action arose; otherwise, such cause of action is
        permanently barred.
      </p>
      <p>
        This Agreement shall be governed by and interpreted in accordance with the laws of the Commonwealth of Massachusetts
        (excluding the conflict of laws rules thereof). All disputes under this Agreement will be resolved in the applicable
        state or federal courts of Massachusetts. Downloader consents to the jurisdiction of such courts and waives any
        jurisdictional or venue defenses otherwise available.
      </p>
      <p class="title">VIII. Integration and Severability</p>
      <p>
        This Agreement represents the entire agreement between Downloader and Data Provider with respect to the downloading
        and use of the Materials and supersedes all prior or contemporaneous communications and proposals (whether oral,
        written, or electronic) between Downloader and Data Provider with respect to downloading or using the Materials. If
        any provision of this Agreement is found to be unenforceable or invalid, that provision will be limited or eliminated
        to the minimum extent necessary so that the Agreement will otherwise remain in full force and effect and enforceable.
      </p>
      <p class="title">IX. Miscellaneous</p>
      <p>
        Data Provider may assign, transfer or delegate any of its rights and obligations hereunder without consent. No
        agency, partnership, joint venture, or employment relationship is created as a result of the Agreement and neither
        party has any authority of any kind to bind the other in any respect outside of the terms described within this
        Agreement. In any action or proceeding to enforce rights under the Agreement, the prevailing party will be entitled
        to recover costs and attorneys’ fees.
      </p>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'AchoDataUsageAgreement',

  props: {
    dataUsageAgreementVisible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {};
  },

  mounted() {},
  methods: {
    closeDataUsageAgreement() {
      this.$emit('close-data-usage-agreement');
    }
  }
};
</script>
<style scoped>
.title {
  font-weight: 500;
}
</style>
<style>
#data-usage-agreement-dialog {
  text-align: initial;
}

#data-usage-agreement-dialog .el-dialog {
  max-width: 40.5rem;
  min-width: 38.5rem;
}

#data-usage-agreement-dialog .el-dialog__header,
#data-usage-agreement-dialog .el-dialog__body,
#data-usage-agreement-dialog .el-dialog__footer {
  padding: 2rem 2rem;
}

#data-usage-agreement-dialog .el-dialog__body {
  padding-top: 0;
  padding-bottom: 2rem;
}
</style>
