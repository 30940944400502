<template>
  <div :key="colIndex" class="table-header-cell">
    <div class="current-rename-column">
      <span>
        <p class="column-name">
          {{ colName }}
        </p>
        <span
          class="column-type"
          :style="{ border: `1px solid ${dataTypeColor(colType).color}`, color: dataTypeColor(colType).color }"
        >
          {{ colType.charAt(0) + colType.slice(1).toLowerCase() }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColumnHeaderCell',
  props: {
    colIndex: {
      type: Number,
      default: -1
    },
    colType: {
      type: String,
      default: ''
    },
    colName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: ''
    };
  },

  mounted() {
    this.value = this.colName;
  },

  methods: {
    dataTypeColor(type) {
      switch (type) {
        case 'STRING':
          return {
            color: '#828282'
          };
        case 'BYTE':
          return {
            color: '#9B51E0'
          };
        case 'INTEGER':
          return {
            color: '#F2C94C'
          };
        case 'FLOAT':
          return {
            color: '#27AE60'
          };
        case 'NUMERIC':
          return {
            color: '#6FCFBE'
          };
        case 'DATE':
          return {
            color: '#2D9CDB'
          };
        case 'DATETIME':
          return {
            color: '#56CCF2'
          };
        case 'TIME':
          return {
            color: '#2DDBD1'
          };
        case 'TIMESTAMP':
          return {
            color: '#6AA0D2'
          };
        case 'BOOLEAN':
          return {
            color: '#F2994A'
          };
        default:
          return {
            color: '#828282' // NOTE: same as STRING
          };
      }
    }
  }
};
</script>

<style scoped>
.table-header-cell {
  height: 26px;
}

/* col schema */
.column-name {
  display: inline-block;
  font-size: 14px;
  margin: 5px 0 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* NOTE: max-width this is important! */
  max-width: 420px;
  vertical-align: bottom;
}

.column-type {
  /* color: #fff; */
  font-weight: 500;
  font-size: 11px;
  margin-left: 5px;
  margin-right: 0;
  margin-top: 4px;
  padding: 2px 4px 2px;
  border-radius: 10px;
  float: right;
}
</style>
