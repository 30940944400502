import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    embedTableData: [],
    embedTableColumnList: [],
    embedTableMetaData: {
      embed_id: -1,
      proj_id: -1,
      view_id: -1,
      view_name: '',
      company: '',
      total_row_count: 0,
      contact_email: '',
      download_method: 'acho',
      requirement: {
        email: false,
        payment: false,
        payment_amount: null
      },
      customization: {
        remove_logo: false,
        display_company_info: false
      },
      table_size: 0
    },
    embedTableLoading: true,
    embedTableError: false
  },
  mutations: {
    setEmbedTableData(state, payload) {
      state.embedTableData = payload.exportData;
      state.embedTableColumnList = payload.schema;
      state.embedTableMetaData = {
        embed_id: payload.embed_id,
        proj_id: payload.proj_id,
        view_id: payload.view_id,
        view_name: payload.view_name,
        company: payload.company,
        total_row_count: payload.total_row_count,
        contact_email: payload.contact_email,
        download_method: payload.download_method,
        requirement: payload.requirement,
        customization: payload.customization,
        table_size: payload.table_size
      };
    },

    resetEmbedTableData(state) {
      state.embedTableData = [];
      state.embedTableColumnList = [];
      state.embedTableMetaData = {
        embed_id: -1,
        proj_id: -1,
        view_id: -1,
        view_name: '',
        company: '',
        total_row_count: 0,
        contact_email: '',
        download_method: 'acho',
        requirement: {
          email: false,
          payment: false,
          payment_amount: null
        },
        customization: {
          remove_logo: false,
          display_company_info: false
        },
        table_size: 0
      };
      state.embedTableLoading = false;
    },

    setEmbedTableLoading(state, isLoading) {
      state.embedTableLoading = isLoading;
    },

    setEmbedTableError(state, hasError) {
      state.embedTableError = hasError;
    }
  },
  actions: {
    getEmbedTableData({ commit }, embedId) {
      commit('setEmbedTableLoading', true);
      commit('resetEmbedTableData');
      return new Promise((resolve, reject) => {
        axios
          .get(`/project/get-embed-data/${embedId}`)
          .then((res) => {
            if (res && res.data) commit('setEmbedTableData', res.data);
            else commit('resetEmbedTableData');
            resolve(res);
          })
          .catch((err) => {
            commit('resetEmbedTableData');
            commit('setEmbedTableError', true);
            reject(err);
          });
      });
    }
  },
  getters: {
    getSampleTableRowCount(state) {
      return state.embedTableData.length || 0;
    },
    getTotalTableRowCount(state) {
      return state.embedTableMetaData.total_row_count || 0;
    }
  },
  modules: {}
});
