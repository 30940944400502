<template>
  <div class="main-page-container"><router-view></router-view></div>
</template>

<script>
export default {
  name: 'MainFrame'
};
</script>

<style scoped></style>
