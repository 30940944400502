<template>
  <div v-loading.fullscreen.lock="embedLoading" element-loading-text="Loading" class="embed-home-wrapper">
    <el-dialog
      id="download-dialog"
      title="Download Original"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
      :append-to-body="true"
      :visible.sync="downloadDialogVisible"
    >
      <div>
        <el-row :gutter="40">
          <el-col :span="12">
            <div class="download-info-title">Dataset name</div>
            <div class="download-info-content">{{ embedTableMetaData.view_name }}</div>
          </el-col>
          <el-col :span="12">
            <div class="download-info-title">Dataset details</div>
            <div class="download-info-content">
              {{ embedTableMetaData.total_row_count.toLocaleString() }} rows,
              {{ readableBytes(embedTableMetaData.table_size) }}
            </div>
          </el-col>
        </el-row>

        <el-row style="margin-top: 1.5rem;" :gutter="40">
          <el-col :span="12">
            <div class="download-info-title">Provider</div>
            <div class="download-info-content">{{ embedTableMetaData.company }}</div>
          </el-col>
          <el-col :span="12">
            <div class="download-info-title">Price</div>
            <div class="download-info-content">
              {{
                embedTableMetaData.requirement.payment
                  ? `$${embedTableMetaData.requirement.payment_amount} / download`
                  : 'Free'
              }}
            </div>
          </el-col>
        </el-row>

        <el-row v-if="embedTableMetaData.requirement.email" style="margin-top: 1rem;" :gutter="40">
          <el-col :span="24">
            <p class="input-title required">Email</p>
            <el-input v-model="email" placeholder="Email"></el-input>
          </el-col>
        </el-row>

        <el-row v-if="embedTableMetaData.requirement.payment" style="margin-top: 1rem;" :gutter="40">
          <el-col :span="12">
            <p class="input-title required">First name</p>
            <el-input v-model="paymentFirstName" placeholder="First Name"></el-input>
          </el-col>
          <el-col :span="12">
            <p class="input-title required">Last name</p>
            <el-input v-model="paymentLastName" placeholder="Last Name"></el-input>
          </el-col>
        </el-row>
        <el-row v-show="embedTableMetaData.requirement.payment" style="margin-top: 1rem;">
          <p class="input-title required">Card info</p>
          <div
            id="card-element"
            :style="{
              height: 'auto',
              border: '1px solid #ced4da',
              padding: '0.5rem',
              margin: '5px auto',
              borderRadius: '4px'
            }"
          >
            <!-- A Stripe Element will be inserted here. -->
          </div>
          <!-- Used to display form errors. -->
          <div id="card-errors" role="alert" style="color: #f56c6c; margin-top: 3px;"></div>
        </el-row>

        <el-row style="margin-top: 1.5rem;">
          <input
            v-model="checkAgreement"
            type="checkbox"
            style="width: 12px; margin: 0 2px 2px 0; vertical-align: bottom; cursor: pointer;"
          />
          <span class="required">
            I've read and agree to
            <a class="document-link" @click="dataUsageAgreementVisible = true">
              Acho Data Agreement
            </a>
          </span>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="height: 33.5px;">
          <AchoButton
            class="action-btn"
            style="margin-right: 0; float: right;"
            type="primary"
            :disabled="embedTableError"
            @click="downloadOriginal"
          >
            {{ embedTableMetaData.requirement.payment ? 'Checkout' : 'Download' }}
          </AchoButton>
          <div
            v-show="embedTableMetaData.requirement.payment"
            ref="paypalCheckout"
            style="width: 150px; float: right; margin-right: 18px;"
          ></div>
          <!-- <AchoButton class="action-btn" type="default" @click="downloadDialogVisible = false">
          Cancel
        </AchoButton> -->
        </div>
      </span>
    </el-dialog>
    <AchoDataUsageAgreement
      :data-usage-agreement-visible="dataUsageAgreementVisible"
      @close-data-usage-agreement="handleCloseDataUsageAgreement"
    />
    <!-- [Success | Error] -->
    <!-- 1. header -->
    <div
      v-if="!embedTableLoading && getTotalTableRowCount"
      class="embed-table-header"
      :style="embedTableError ? 'height: 3rem;' : ''"
    >
      <div class="content-wrapper" style="margin-top: 0.5rem;">
        <!-- 1.1 left part (table name, company) -->
        <div class="content-left-wrapper">
          <div v-if="!embedTableError" class="table-name">
            {{ embedTableMetaData.view_name }}
          </div>
          <p
            v-if="embedTableMetaData.customization.display_company_info"
            :class="embedTableError ? '' : 'non-mobile'"
            class="provided-by"
            style="marign-bottom: -2px;"
          >
            data provided by {{ embedTableMetaData.company }}
            <span v-if="embedTableMetaData.contact_email">({{ embedTableMetaData.contact_email }})</span>
          </p>
          <p
            v-if="!embedTableMetaData.customization.display_company_info && embedTableMetaData.contact_email"
            :class="embedTableError ? '' : 'non-mobile'"
            class="provided-by"
            style="marign-bottom: -2px;"
          >
            data provided by {{ embedTableMetaData.contact_email }}
          </p>
          <!-- <div :class="['info-icon-wrapper', embedTableError ? '' : 'non-mobile']">
            <img class="acho-icon" :src="achoIcon" alt="info" style="width: 1.2rem; vertical-align: sub;" />
          </div> -->
        </div>
      </div>
    </div>
    <!-- [Error] no data! -->
    <div v-if="embedTableError" class="embed-error">
      <div class="info-icon-wrapper" style="margin-left: 1rem; margin-top: 0;">
        <img class="warn-icon" :src="warnIcon" alt="warn" style="width: 1.2rem; vertical-align: top;" />
      </div>
      <p style="text-align: left; font-size: 14px; margin: 0 0.5rem; line-height: 1.4rem;">
        The original table has been deleted by the admin.
      </p>
    </div>

    <!-- [Success] has table data! -->
    <div v-else class="embed-home">
      <!-- 2.1 table -->
      <div v-if="!embedTableLoading">
        <VirtualTable />
      </div>

      <!-- 2.2 table loading -->
      <div v-else class="embed-table-loading">
        <div class="acho-logo-wrapper">
          <img class="acho-logo" :src="achoLogo" alt="info" style="width: 6rem;" />
        </div>
        <p class="loading-dots">Loading</p>
      </div>
    </div>

    <!-- [Success | Error] -->
    <!-- 3. footer -->
    <div
      v-if="!embedTableLoading && getTotalTableRowCount"
      class="embed-table-footer"
      :style="embedTableError ? 'height: 4rem;' : ''"
    >
      <div class="content-wrapper">
        <!-- 3.1 left part (table summary) -->
        <div class="content-left-wrapper">
          <div v-if="!embedTableError" class="info-icon-wrapper">
            <img class="acho-icon mobile" :src="achoIcon" alt="info" style="width: 1.2rem;" @click="goToAchoHome" />
            <img class="info-icon non-mobile" :src="infoIcon" alt="info" style="width: 1rem; vertical-align: text-top;" />
          </div>
          <p v-if="!embedTableError" style="font-size: 14px; margin: auto 0 auto 0.5rem;">
            Sample: {{ getSampleTableRowCount.toLocaleString() }} rows; Original:
            {{ getTotalTableRowCount.toLocaleString() }} rows
          </p>

          <p
            v-if="!embedTableMetaData.customization.remove_logo"
            :class="embedTableError ? '' : 'non-mobile'"
            class="provided-by"
          >
            powered by Acho
          </p>
          <div
            v-if="!embedTableMetaData.customization.remove_logo"
            :class="['info-icon-wrapper', embedTableError ? '' : 'non-mobile']"
          >
            <img
              class="acho-icon"
              :src="achoIcon"
              alt="info"
              style="width: 1.2rem; vertical-align: sub;"
              @click="goToAchoHome"
            />
          </div>
        </div>

        <!-- 3.2 right part (action buttons) -->
        <div class="non-desktop" style="margin-right: 1.5rem;">
          <v-popover offset="5" placement="top-end">
            <!-- popover target (for the events and position) -->
            <div class="tooltip-target" style="width: 1.2rem; margin-top: 0.2rem;">
              <img class="menu-icon" :src="menuIcon" alt="info" style="width: 1.2rem;" />
            </div>

            <!-- popover content -->
            <template slot="popover">
              <div class="action-btn-group">
                <AchoButton class="action-btn" type="primary" :disabled="embedTableError" @click="openDownloadDialog">
                  Download Original
                </AchoButton>
                <AchoButton class="action-btn" type="default" :disabled="embedTableError" @click="downloadSample">
                  Download Sample
                </AchoButton>
              </div>
            </template>
          </v-popover>
        </div>
        <div class="content-right-wrapper desktop">
          <AchoButton
            class="action-btn"
            type="default"
            style="margin-right: 1rem;"
            :disabled="embedTableError"
            @click="downloadSample"
          >
            Download Sample
          </AchoButton>
          <AchoButton class="action-btn" type="primary" :disabled="embedTableError" @click="openDownloadDialog">
            Download Original
          </AchoButton>
        </div>
      </div>
    </div>
    <!-- END footer -->
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapGetters } from 'vuex';

import AchoDataUsageAgreement from '@/views/AchoDataUsageAgreement.vue';
import AchoButton from '@/components/basicComponents/AchoButton.vue';
import VirtualTable from '@/components/VirtualTable.vue';

import { constants } from '@/utils/globalConstants';

export default {
  name: 'EmbedHome',

  components: {
    AchoDataUsageAgreement,
    AchoButton,
    VirtualTable
  },

  data() {
    return {
      infoIcon: require('@/assets/info.svg'),
      warnIcon: require('@/assets/warning.svg'),
      achoIcon: require('@/assets/logo.svg'),
      achoLogo: require('@/assets/acho_logo.svg'),
      menuIcon: require('@/assets/menu.svg'),
      embedHash: '',
      hostURL: '',
      // dialog
      embedLoading: false,
      downloadDialogVisible: false,
      dataUsageAgreementVisible: false,
      email: '',
      paymentFirstName: '',
      paymentLastName: '',
      checkAgreement: false,
      // Stripe
      stripeIntent: {},
      stripe: null,
      constants
    };
  },

  computed: {
    ...mapState({
      embedTableLoading: (state) => state.embedTableLoading,
      embedTableError: (state) => state.embedTableError,
      embedTableData: (state) => state.embedTableData,
      embedTableMetaData: (state) => state.embedTableMetaData,
      embedTableColumnList: (state) => state.embedTableColumnList
    }),
    ...mapGetters(['getSampleTableRowCount', 'getTotalTableRowCount'])
  },

  async mounted() {
    // 1. try get embed id from url
    try {
      this.embedHash = this.$route.params.embed_id;
    } catch (error) {
      // no embed id, to default page
      this.$route.replace('/embed');
      return;
    }

    // 2. get table data
    await this.$store.dispatch('getEmbedTableData', this.embedHash);

    // normally document.referrer is the right one
    this.hostURL = window.location != window.parent.location ? document.referrer : document.location.href;
    const achoEmbedHostURL = window.sessionStorage.getItem('achoEmbedHostURL');
    if (achoEmbedHostURL === this.hostURL) {
      // refreshed, do nothing
    } else {
      window.sessionStorage.setItem('achoEmbedHostURL', this.hostURL);
      // call backend, add view count
      axios
        .post('/embed/stat', {
          embed_id: this.embedTableMetaData.embed_id,
          host_url: this.hostURL
        })
        .then((res) => {})
        .catch((err) => {});
    }

    // 3. Paypal
    window.paypal
      .Buttons({
        onClick: (data, actions) => {
          // check if agreed
          if (!this.checkAgreement) {
            this.$message({
              message: 'Please read and agree to Acho Data Agreement before paying with Paypal.',
              type: 'warning'
            });
            return actions.reject();
          }
          return actions.resolve();
        },
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: this.embedTableMetaData.view_name,
                amount: {
                  currency_code: 'USD',
                  value: this.embedTableMetaData.requirement.payment ? this.embedTableMetaData.requirement.payment_amount : 0
                }
              }
            ]
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then((orderData) => {
            axios
              .post(
                '/embed/record-paypal-transaction',
                {
                  host_url: this.hostURL,
                  embed_id: this.embedTableMetaData.embed_id,
                  customer_email: orderData?.payer?.email_address,
                  firstname: orderData?.payer?.name?.given_name,
                  lastname: orderData?.payer?.name?.surname,
                  orderID: data.orderID,
                  payerID: data.payerID
                },
                {
                  headers: {
                    authorization: this.getUserToken
                  }
                }
              )
              .then((res) => {
                this.$message({
                  message: `Payment received! Your file download should start right away and you will receive an email with the download link. If your download doesn't start right away, it may have been blocked by your web browser.`,
                  type: 'success',
                  showClose: true
                });
                window.open(res.data, '_blank');
                this.embedLoading = false;
                this.downloadDialogVisible = false;
                this.resetForm();
              })
              .catch((err) => {
                this.embedLoading = false;
                console.log(err);
                this.$message.error('Something went wrong, please refresh and try again.');
              });
          });
        },
        onError: (err) => {
          console.error(err);
        },
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'horizontal',
          label: 'pay',
          height: 33.5,
          tagline: false
        }
      })
      .render(this.$refs.paypalCheckout);
  },
  methods: {
    downloadSample() {
      // const csv = Papa.unparse(this.embedTableData);
      const downloadUrl = window.URL.createObjectURL(
        new Blob([new Uint8Array(this.toCsv())], { type: 'text/csv;charset=UTF-16LE' })
      );

      const link = document.createElement('a');
      link.hidden = '';
      link.href = downloadUrl;
      link.download = `acho_export_${this.embedTableMetaData.view_name}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    handleCloseDataUsageAgreement() {
      this.dataUsageAgreementVisible = false;
    },

    openDownloadDialog() {
      if (this.embedTableMetaData.requirement.payment && !this.stripeIntent.id) {
        this.embedLoading = true;
        axios
          .post(
            '/embed/pay/create-intent',
            {
              embed_id: this.embedTableMetaData.embed_id,
              customer_email: this.email
            },
            {
              headers: {
                authorization: this.getUserToken
              }
            }
          )
          .then((res) => {
            this.embedLoading = false;
            this.downloadDialogVisible = true;
            this.stripeIntent = res.data;
            this.renderPayment();
          })
          .catch((err) => {
            this.embedLoading = false;
            if (err.response?.data?.message) {
              this.$message.error('The original data is not ready yet, please try again later.');
            } else {
              this.$message.error('Something went wrong, please refresh and try again.');
            }
          });
      } else {
        this.downloadDialogVisible = true;
      }
    },

    downloadOriginal() {
      // http://localhost:8080/login?
      // hash=7a7f3dea1436d5a22554da51d95d9062d5f488991e1fb2a1c20d75299f75fb094c4faf26d928fb6394df963e42584b22463ea838f1da0ae9ca1fe7d2b7036a14
      // &tableName=nintendo
      // &totalRowCount=365
      // &companyName=Acho
      if (!this.embedHash) return; // something is wrong...

      if (this.embedTableMetaData.download_method === 'acho') {
        // check if agreed
        if (!this.checkAgreement) {
          this.$message({
            message: 'Please read and agree to Acho Data Agreement before download.',
            type: 'warning'
          });
          return;
        }

        const url =
          `${constants.appAddr}/login?hash=${this.embedHash}&` +
          `tableName=${encodeURIComponent(this.embedTableMetaData.view_name)}&` +
          `totalRowCount=${this.embedTableMetaData.total_row_count}&` +
          `companyName=${encodeURIComponent(this.embedTableMetaData.company)}`;

        this.downloadDialogVisible = false;
        this.resetForm();
        window.open(url, '_blank');
      } else {
        // check if email is valid
        if (this.embedTableMetaData.requirement.email) {
          const email = this.email.trim();

          const emailRegex = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
          if (!email || typeof email != 'string' || !email.length || !emailRegex.test(email)) {
            this.$message.error('Please enter a valid email!');
            return;
          }
        }

        // check if name is valid
        if (this.embedTableMetaData.requirement.payment) {
          const regName = /^[a-zA-Z]+$/;
          if (!regName.test(this.paymentFirstName)) {
            this.$message.error('Please enter a valid first name!');
            return;
          }
          if (!regName.test(this.paymentLastName)) {
            this.$message.error('Please enter a valid last name!');
            return;
          }
        }

        // check if agreed
        if (!this.checkAgreement) {
          this.$message({
            message: 'Please read and agree to Acho Data Agreement before download.',
            type: 'warning'
          });
          return;
        }

        // proceed payment if payment required
        if (this.embedTableMetaData.requirement.payment) {
          // paywith card
          this.embedLoading = true;
          this.payWithCard(this.stripe, this.card, this.stripeIntent.client_secret);
        } else {
          this.downloadWithoutPayment();
        }
      }
    },

    goToAchoHome() {
      window.open(constants.homePageAddr, '_blank');
    },

    downloadWithoutPayment() {
      axios
        .post(
          '/embed/dl',
          {
            embed_id: this.embedTableMetaData.embed_id,
            host_url: this.hostURL,
            customer_email: this.email
          },
          {
            headers: {
              authorization: this.getUserToken
            }
          }
        )
        .then((res) => {
          window.open(res.data, '_blank');
          this.embedLoading = false;
          this.downloadDialogVisible = false;
          this.resetForm();
        })
        .catch((err) => {
          this.embedLoading = false;
          if (err.response?.data?.message) {
            this.$message.error('The original data is not ready yet, please try again later.');
          } else {
            this.$message.error('Something went wrong, please refresh and try again.');
          }
        });
    },

    // verify payment and download
    verifyAndDownload() {
      axios
        .post(
          '/embed/pay/verify-and-dl',
          {
            intentId: this.stripeIntent.id,
            host_url: this.hostURL,
            firstname: this.paymentFirstName,
            lastname: this.paymentLastName,
            customer_email: this.email
          },
          {
            headers: {
              authorization: this.getUserToken
            }
          }
        )
        .then((res) => {
          window.open(res.data, '_blank');
          this.embedLoading = false;
          this.downloadDialogVisible = false;
          this.$message({
            message: `Payment received! Your file download should start right away and you will receive an email with the download link. If your download doesn't start right away, it may have been blocked by your web browser.`,
            type: 'success',
            showClose: true
          });
          this.resetForm();
        })
        .catch((err) => {
          this.embedLoading = false;
          this.$message.error('Something went wrong, please refresh and try again.');
        });
    },

    /** *******************************************************************
     * Stripe Functions
     ******************************************************************** */
    // stripe
    renderPayment() {
      // Step 1: import stripe
      // eslint-disable-next-line no-undef
      this.stripe = Stripe(constants.STRIPE_KEY);

      // Step 2: Create an instance of Elements.
      const elements = this.stripe.elements();

      // Custom styling can be passed to options when creating an Element.
      // (Note that this demo uses a wider set of styles than the guide below.)
      const style = {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      };
      // Create an instance of the card Element.
      this.card = elements.create('card', { style });

      setTimeout(() => {
        // Add an instance of the card Element into the `card-element` <div>.
        this.card.mount('#card-element');

        // Handle real-time validation errors from the card Element.
        this.card.on('change', this.showCardError);
      }, 1);
    },

    showCardError(event) {
      // this.paymentLoading = false;
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else if (event.response && event.response.data && event.response.data.error && event.response.data.error.message) {
        displayError.textContent = event.response.data.error.message;
      } else {
        displayError.textContent = '';
      }
    },

    payWithCard(stripe, card, clientSecret) {
      stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card
          }
        })
        .then((result) => {
          if (result.error) {
            // Show error to your customer
            this.embedLoading = false;
            this.showCardError(result);
          } else {
            // route to download
            this.verifyAndDownload();
          }
        });
    },

    /** ********************************************************************
     * Helper functions
     ******************************************************************** */
    resetForm() {
      this.email = '';
      this.paymentFirstName = '';
      this.paymentLastName = '';
      this.checkAgreement = false;
      this.stripeIntent = {};
    },

    toCsv() {
      // 1. add column row
      const colData = this.embedTableColumnList.map((c) => c.name);
      const sampleData = [colData].concat(this.embedTableData);

      // 2. construct csv (TSV)
      let csv = '';
      for (let i = 0; i < sampleData.length; i++) {
        const row = sampleData[i];
        for (let j = 0; j < row.length; j++) {
          let val = row[j] === null ? '' : row[j].toString();
          val = val.replace(/\t/gi, ' ');
          if (j > 0) csv += '\t';
          csv += val;
        }
        csv += '\n';
      }

      // for UTF-16 (other languages)
      let cCode = [];
      const bArr = [];
      bArr.push(255, 254);
      for (let i = 0; i < csv.length; ++i) {
        cCode = csv.charCodeAt(i);
        bArr.push(cCode & 0xff);
        bArr.push((cCode / 256) >>> 0);
      }

      return bArr;
    },

    readableBytes(bytes) {
      if (bytes == 0) return '0.00 MB';
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      // eslint-disable-next-line no-restricted-properties
      return `${(bytes / Math.pow(1024, i)).toFixed(2) * 1} ${sizes[i]}`;
    }
  }
};
</script>

<style scoped>
.embed-error {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 30%;
  width: 100%;
  margin: 0 auto;
}

.embed-home {
  border-bottom: 1px solid #e4e7ed;
  position: relative;
  height: calc(100vh - 1px - 3rem);
  margin-top: 3rem;
  width: 100vw;
}

.embed-table-loading {
  position: absolute;
  top: 30%;
  width: 100%;
  margin: 0 auto;
}

/* loading dots */
.loading-dots {
  color: #828282;
  margin-top: 0.5rem;
  padding-left: 1rem;
}

.loading-dots:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgb(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #828282;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #828282, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #828282, 0.5em 0 0 #828282;
  }
}

.download-info-title {
  font-weight: 500;
  color: #333333;
}

.download-info-content {
  margin-top: 1rem;
  color: #4f4f4f;
}

.embed-table-header {
  position: fixed;
  top: 0;
  height: 3rem;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #e4e7ed;
  border-bottom: none;
  background-color: #fafafa;
}

.embed-table-footer {
  position: fixed;
  bottom: 0;
  /* height: 0; */
  height: 4rem;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #e4e7ed;
  border-top: none;
  background-color: #fafafa;
  /* box-shadow: 0px -2px 4px #e0e0e0; */
  /* transition: height 0.25s ease-in-out; */
}

/* .embed-home-wrapper:hover .embed-table-footer {
  height: 4rem;
} */

.content-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  line-height: 2rem;
}

.content-left-wrapper {
  display: flex;
  justify-content: space-around;
  padding-left: 18px;
}

.info-icon-wrapper {
  margin: auto 0;
}

.table-name {
  font-size: 18px;
  font-weight: 500;
}

.provided-by {
  font-style: italic;
  color: #828282;
  font-size: 12px;
  margin: auto 0.5rem -1px 1rem;
}

.document-link {
  font-weight: 500;
  color: var(--blue);
  white-space: normal;
  text-decoration: none;
}

.document-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.document-link:focus {
  outline: 0;
}

.menu-icon,
.acho-icon {
  cursor: pointer;
}

.content-right-wrapper {
  display: flex;
  justify-content: space-around;
}

.action-btn {
  margin: auto 18px auto 0;
}

.action-btn-group {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.input-title {
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #333333;
}

.required:after {
  content: ' *';
  color: #ff0000;
}

.action-btn-group .action-btn {
  margin: 0.5rem;
}

.mobile {
  display: block;
}

.non-mobile {
  display: none;
}

.desktop {
  display: none;
}

@media screen and (min-width: 580px) {
  .mobile {
    display: none;
  }
  .non-mobile {
    display: block;
  }
}

@media screen and (min-width: 790px) {
  .non-desktop {
    display: none;
  }
  .desktop {
    display: block;
  }
}
</style>
<style>
#download-dialog {
  text-align: initial;
}

#download-dialog .el-dialog {
  max-width: 40.5rem;
  min-width: 38.5rem;
}

#download-dialog .el-dialog__header,
#download-dialog .el-dialog__body,
#download-dialog .el-dialog__footer {
  padding: 2rem 2rem;
}

#download-dialog .el-dialog__body {
  padding-top: 0;
  padding-bottom: 0;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^='top'] {
  margin-bottom: 5px;
}

.tooltip[x-placement^='top'] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^='bottom'] {
  margin-top: 5px;
}

.tooltip[x-placement^='bottom'] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^='right'] {
  margin-left: 5px;
}

.tooltip[x-placement^='right'] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^='left'] {
  margin-right: 5px;
}

.tooltip[x-placement^='left'] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
</style>
