<template>
  <div class="info-mask" :class="{ 'mask-not-visible': visible === false }">
    <div class="info-wrapper">
      <div class="info-items">
        <div class="info-msg">{{ message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoMask',

  props: {
    message: {
      type: String,
      default: 'The content you are looking for does not exist'
    },
    visible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.info-mask {
  position: absolute;
  z-index: 2000;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.3s;
}
.mask-not-visible {
  display: none;
}
.info-wrapper {
  position: absolute;
  top: 50%;
  width: 100%;
}

.info-msg {
  margin: 1rem 0;
  text-align: center;
  font-weight: bold;
  color: var(--blue);
}
</style>
