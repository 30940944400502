export const isProduction = process.env.VUE_APP_IS_PRODUCTION || false;

export const constants = {
  /** *******************************************************************
   * NOTE: Basic Constants
   ******************************************************************** */
  version: process.env.VUE_APP_VERSION || '1.0.0',
  serverAddr: process.env.VUE_APP_SERVER_ADDR || '',
  homePageAddr: process.env.VUE_APP_HOME_PAGE_ADDR || '',
  appAddr: process.env.VUE_APP_FRONTEND_ADDR || '',
  // Payment
  STRIPE_KEY: process.env.VUE_APP_STRIPE_KEY || ''
};
