<template>
  <div v-loading="loading" class="spreadsheet-app-container">
    <InfoMask :visible="maskVisible" :message="infoMsg" />
    <AchoSpreadsheetApp
      ref="achoSpreadsheetApp"
      :toolbar-config="toolbarConfig"
      :columns="columns"
      :default-filter="filterObj"
      :default-hidden="hiddenColumns"
      :default-sort-list="sortList"
      @onHideFields="handleHideFields"
      @onFilter="handleFilter"
      @onSort="handleSort"
      @page-end-reached="handlePageEnd"
    />
  </div>
</template>

<script>
import { getPublicAsset } from '@/api/asset';
import { getWidgetsByPubAppId, getWidgetData, applyPublicAction } from '@/api/businessApps';
import AchoSpreadsheetApp from '@acho-inc/spreadsheet-app';
import InfoMask from '@/components/InfoMask';

export default {
  name: 'SpreadsheetApp',

  components: {
    AchoSpreadsheetApp,
    InfoMask
  },

  data() {
    return {
      loading: false,
      infoMsg: '',
      maskVisible: false,
      appId: undefined,
      assetId: undefined,
      spreadsheetWidget: {},
      toolbarConfig: {
        visible: true,
        enableHideColumns: false,
        enableFilter: false,
        enableSort: false
      },
      columns: [],
      hiddenColumns: [],
      filterObj: { connectors: [], options: [] },
      sortList: [],
      totalRows: 0,
      totalPages: 0
    };
  },

  mounted() {
    this.$refs.achoSpreadsheetApp.loadColumns([]);
    this.$refs.achoSpreadsheetApp.loadRows([]);
    this.init();
  },

  methods: {
    async init() {
      this.loading = true;
      const { assetId } = this.$route.params;
      await this.loadSpreadsheetApp(assetId);
      this.loading = false;
    },

    async loadSpreadsheetApp(assetId) {
      try {
        // Fetch the asset's record, and verify that it has public access
        const pubAssetResp = await getPublicAsset({ assetId, withRules: true });
        if (pubAssetResp.data.error) {
          // console.log(pubAssetResp.data.error);
          this.handleNoContent('This content is not publicly available');
          return;
        }
        const pubAsset = pubAssetResp.data;
        const { id: appId, rules } = pubAsset;
        // console.log('APP ID: ', appId);
        this.setSpreadsheetRules(JSON.parse(rules));

        // Fetch the widgets corresponding to the spreadsheet app (should only be one widget)
        const widgetListResp = await getWidgetsByPubAppId(appId);
        const { data: widgetList } = widgetListResp;

        if (!Array.isArray(widgetList) || widgetList.length === 0) {
          this.handleNoContent();
          return;
        }

        [this.spreadsheetWidget] = widgetList;
        await this.loadSpreadsheetData();

        // Holding onto the asset id and app id as they may come in handy later
        this.assetId = assetId;
        this.appId = appId;
      } catch (error) {
        // console.log('Error getting public asset: ', error);
        this.handleNoContent();
      }
    },

    async loadSpreadsheetData() {
      try {
        const { app_id, id } = this.spreadsheetWidget;
        // Getting guestId if it exists
        const guestId = this.getGuestId(app_id);

        // Fetching widget data (with guestId if we have one)
        const params = { appId: app_id, widgetId: id, ...(guestId && { guestId }) };
        const dataResp = await getWidgetData(params);
        const { data } = dataResp.data;
        // console.log(data);

        const { config, payload, guestId: _guestId, totalRows, totalPages } = data;
        // Updating guestId
        this.setGuestId(app_id, _guestId);
        this.totalRows = totalRows;
        this.totalPages = totalPages;

        // Loading the spreadsheet's data
        this.columns = [...config.columns];
        const _hidden = Array.isArray(config.hiddenColumns) ? config.hiddenColumns : [];
        this.hiddenColumns = _hidden;
        const nonHiddenCols = config.columns.filter((col) => !_hidden.includes(col.index));

        this.$refs.achoSpreadsheetApp.loadColumns(nonHiddenCols);
        this.$refs.achoSpreadsheetApp.loadRows(payload, { total: totalRows, reload: true }); // sets rows

        // loading filterObj and sortList from config if they exist
        if (Array.isArray(config?.filterObj?.options)) {
          this.filterObj.options = config.filterObj.options;
        }
        if (Array.isArray(config.filterObj?.connectors)) {
          this.filterObj.connectors = config.filterObj.connectors;
        }
        if (Array.isArray(config.sortList)) {
          this.sortList = config.sortList;
        }
      } catch (error) {
        // console.log('error loading spreadsheet data: ', error);
        this.handleNoContent();
      }
    },

    async handlePageEnd({ page }) {
      // console.log('Page end: ', page);
      try {
        const _page = page + 1;
        if (_page > this.totalPages) {
          return;
        }

        const { app_id, id } = this.spreadsheetWidget;
        // Getting guestId if it exists
        const guestId = this.getGuestId(app_id);

        const appType = 'spreadsheet';
        const actionType = 'query';

        // Fetching widget data with respect to the page
        const { hiddenColumns, filterObj, sortList } = this;
        const queryParams = { hiddenColumns, filterObj, sortList };
        const params = { appId: app_id, widgetId: id, guestId, appType, actionType, queryParams, page: _page };
        const resp = await applyPublicAction(params);

        const { payload, totalRows, totalPages } = resp.data;
        this.totalRows = totalRows;
        this.totalPages = totalPages;

        // Append the rows to the spreadsheet
        this.$refs.achoSpreadsheetApp.loadRows(payload, { total: totalRows });
      } catch (error) {
        // console.log('error in pagination handler: ', error);
        this.handleNoContent('Something went wrong on our end... Please try refreshing.');
      }
    },

    getGuestId(appId) {
      const key = `app_${appId}_guest_id`;
      const guestId = window.localStorage.getItem(key);
      return guestId;
    },

    setGuestId(appId, guestId) {
      if (!guestId) {
        return;
      }
      const key = `app_${appId}_guest_id`;
      window.localStorage.setItem(key, `${guestId}`);
    },

    setSpreadsheetRules(rules) {
      const { enableFilter, enableHideColumns, enableSort } = rules;
      this.toolbarConfig.enableFilter = enableFilter;
      this.toolbarConfig.enableHideColumns = enableHideColumns;
      this.toolbarConfig.enableSort = enableSort;
    },

    handleNoContent(msg) {
      const _msg = msg || 'The content you are looking for does not exist';
      this.infoMsg = _msg;
      this.maskVisible = true;
      this.loading = false;
    },

    handleHideFields(params) {
      // console.log('hide fields: ', params);
      const { hideColumns } = params;
      this.hiddenColumns = hideColumns.map((h) => h);
      this.applyQuery();
    },
    handleFilter(params) {
      // console.log('filter: ', params);
      const { filterObj } = params;
      this.filterObj.connectors = filterObj.connectors.map((c) => c);
      this.filterObj.options = filterObj.options.map((opt) => ({ ...opt }));
      this.applyQuery();
    },
    handleSort(params) {
      // console.log('sort: ', params);
      const { sortList } = params;
      this.sortList = sortList.map((s) => s);
      this.applyQuery();
    },
    async applyQuery() {
      try {
        this.loading = true;
        const { hiddenColumns, filterObj, sortList } = this;
        const queryParams = { hiddenColumns, filterObj, sortList };

        const { app_id: appId, id: widgetId } = this.spreadsheetWidget;
        const guestId = this.getGuestId(appId);

        const appType = 'spreadsheet';
        const actionType = 'query';

        const reqParams = { queryParams, appId, widgetId, guestId, appType, actionType };
        // console.log(reqParams);

        const resp = await applyPublicAction(reqParams);
        // console.log(resp.data);

        const { payload, totalRows, totalPages } = resp.data;
        this.totalRows = totalRows;
        this.totalPages = totalPages;

        const _columns = this.columns.filter((col) => !hiddenColumns.includes(col.index));
        this.$refs.achoSpreadsheetApp.loadColumns(_columns);
        this.$refs.achoSpreadsheetApp.loadRows(payload, { total: totalRows, reload: true });

        this.loading = false;
      } catch (error) {
        this.handleNoContent('Something went wrong on our end... Please try refreshing.');
        // console.log('something went wrong applying query: ', error);
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.spreadsheet-app-container {
  height: calc(100vh - 1rem);
  padding: 0 1rem;
  overflow: hidden;
}
</style>
